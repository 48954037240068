:root {
  --plm-color-primary: #0f768a;
  --plm-color-primary-rgb: 15, 118, 138;
  --plm-color-secondary: #073845;
  --plm-color-alternative: #fff;
  --plm-color-light: #fff;
}

.fs-7 {
  font-size: .8rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.lf-amplify.authenticator {
  height: 100vh;
}

.amplify-image {
  height: 150px;
}

.btn-primary {
  --bs-btn-bg: var(--plm-color-secondary);
  --bs-btn-border-color: var(--bs-btn-bg);
}

.btn-primary:hover {
  --bs-btn-hover-bg: var(--plm-color-alternative);
  --bs-btn-hover-border-color: var(--bs-btn-hover-bg);
  --bs-btn-hover-color: var(--plm-color-primary);
}

.btn-primary.disabled {
  --bs-btn-disabled-bg: var(--plm-color-secondary);
  --bs-btn-disabled-border-color: var(--plm-color-secondary);
}

.btn-secondary {
  --bs-btn-color: var(--plm-color-primary);
  --bs-btn-bg: var(--plm-color-alternative);
  --bs-btn-border-color: var(--plm-color-primary);
}

.btn-secondary:hover {
  --bs-btn-hover-color: var(--plm-color-alternative);
  --bs-btn-hover-bg: var(--plm-color-primary);
  --bs-btn-hover-border-color: var(--plm-color-primary);
}

.btn-secondary svg {
  fill: var(--plm-color-primary);
}

.btn-secondary:hover svg {
  fill: var(--plm-color-alternative);
}

.bg-body {
  background-color: var(--plm-color-primary) !important;
}

.navbar-brand {
  --bs-navbar-brand-color: var(--plm-color-light);
}

.navbar-brand:hover {
  --bs-navbar-brand-hover-color: var(--plm-color-light);
}

.breadcrumb-item {
  color: #000;
}

.breadcrumb-item.active {
  --bs-breadcrumb-item-active-color: #000
}

.breadcrumb-item+.breadcrumb-item::before {
  --bs-breadcrumb-divider-color: #000;
}

[data-amplify-authenticator] {
  .amplify-tabs__item {
    --amplify-components-tabs-item-color: var(--plm-color-light);
    --amplify-components-tabs-item-background-color: var(--plm-color-primary);
    text-decoration: underline;
    --amplify-components-tabs-item-border-color: var(--plm-color-primary);
  }

  .amplify-tabs__item:hover {
    text-decoration: none;
    --amplify-components-tabs-item-hover-color: var(--plm-color-light);
  }

  .amplify-tabs__item--active {
    --amplify-components-tabs-item-active-color: var(--plm-color-primary);
    --amplify-components-tabs-item-active-background-color: var(--plm-color-light);
    text-decoration: none;
  }

  .amplify-tabs__item--active:hover {
    --amplify-components-tabs-item-hover-color: var(--plm-color-primary) !important;
    --amplify-components-tabs-item-background-color: var(--plm-color-light);
    --amplify-components-tabs-item-hover-color: var(--plm-color-light);
  }
}