a {
    --bs-link-color-rgb: none;
    text-decoration-color: rgba(var(--plm-color-primary-rgb), 0.5);
}

a:hover {
    --bs-link-color-rgb: none;
}

nav[aria-label=breadcrumb] {
    --bs-breadcrumb-divider: '>';
}

.accordion-button:not(.collapsed),
.accordion-button.collapsed {
    --bs-accordion-active-bg: var(--plm-color-alternative);
    --bs-accordion-active-color: #000;
    --bs-accordion-btn-color: #000;
}

.accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--plm-color-primary-rgb), 0.25);
}